import React from 'react'
import styles from './styles.module.scss'

const ItemBooleanMany = ({
  title,
  value,
  number,
  tests,
  setTests,
  setRadio,
  radio,
  onChange,
  itemValue,
}) => {
  return (
    <div className={styles.answer}>
      <div className={styles.answer__top}>
        <p className={styles.answer__title}>{title}.</p>
        <p className={styles.answer__value}>{itemValue}</p>
      </div>
      <div className={styles.answer__radioBox}>
        <input
          value='True'
          type='radio'
          checked={value === 'True'}
          onClick={(e) => onChange(e.target.value)}
          // onClick={handleDataTest}
        />
        <label className={styles.answer__label} htmlFor='True'>
          True
        </label>
        <input
          value='False'
          type='radio'
          checked={value === 'False'}
          onClick={(e) => onChange(e.target.value)}
          // onClick={handleDataTest}
        />
        <label className={styles.answer__label} htmlFor='False'>
          False
        </label>
      </div>
    </div>
  )
}

export default ItemBooleanMany

import React from 'react'
import { useFormik } from 'formik'
import { emailSchema } from 'validations'

//sections
import { ModalMain } from '..'
import { Email } from 'assets'
//styles
import styles from './styles.module.scss'
import looserSuperMini from '../../assets/looserSuperMini.png'
import looserMini from '../../assets/looserMini.png'
import looser from '../../assets/looser.png'
import win from '../../assets/win.png'
import winMini from '../../assets/winMini.png'
import winSuperMini from '../../assets/winSuperMini.png'
import { fetchRequest } from '../../utils/fetchRequest'
import { useNavigate } from 'react-router-dom'
import { removeToken } from '../../helpers/tokenLS.js'

const CustomAlert = ({
  showModal,
  closeModal,
  title,
  text,
  titleBtn = 'Delete',
  isOpen,
  onClick,
  medal,
  mark,
}) => {
  const navigate = useNavigate()

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: emailSchema,
    initialValues: {
      email: '',
    },

    onSubmit: async (values) => {
      const sendData = {
        email: formik.values.email,
        mark: `${mark}`,
      }
      const response = await fetchRequest(
        'api/exam/send-result',
        'POST',
        sendData
      )
      if (response.status === 200) {
        closeModal()
        navigate(-1)
        removeToken('accessToken')
      }
    },
  })

  const back = () => {
    navigate(-1)
    removeToken('accessToken')
  }

  return (
    <ModalMain
      showModal={showModal}
      closeModal={closeModal}
      classes={styles.alert}
      isOpen={isOpen}
    >
      {medal === 'Win' && (
        <div className={styles.bigPhoto}>
          <img src={win} width='100%' height='200' alt='win' />
        </div>
      )}
      {medal === 'Win' && (
        <div className={styles.miniPhoto}>
          <img src={winMini} width='100%' height='163' alt='winMini' />
        </div>
      )}
      {medal === 'Win' && (
        <div className={styles.superMiniPhoto}>
          <img
            src={winSuperMini}
            width='100%'
            height='163'
            alt='winSuperMini'
          />
        </div>
      )}

      {medal === 'Looser' && (
        <div className={styles.bigPhoto}>
          <img src={looser} width='100%' height='200' alt='looser' />
        </div>
      )}
      {medal === 'Looser' && (
        <div className={styles.miniPhoto}>
          <img src={looserMini} width='100%' height='163' alt='looserMini' />
        </div>
      )}
      {medal === 'Looser' && (
        <div className={styles.superMiniPhoto}>
          <img
            src={looserSuperMini}
            width='100%'
            height='163'
            alt='looserSuperMini'
          />
        </div>
      )}
      <div className={styles.container}>
        {medal === 'Win' && <h2>{mark}/32 of the answers were correct</h2>}
        {medal === 'Looser' && <h2>{mark}/32 of the answers were correct</h2>}
        {medal === 'Win' && (
          <p className={styles.description}>
            Do you want to see all the general answers on this certificate?
          </p>
        )}
        {medal === 'Looser' && (
          <p className={styles.description}>You can repeat in an hour</p>
        )}
        {medal === 'Win' && (
          <div className={styles.inputBox}>
            <div className={styles.phoneIcon}>
              <Email />
            </div>
            <div className={styles.inputContainer}>
              <p className={styles.p}>Your email address</p>
              <input
                onChange={(value) => {
                  formik.handleChange('email')(value)
                  delete formik.errors.email
                }}
                value={formik.values.email}
                error={formik.errors.email}
                label='email'
                id='email'
                name='email'
                type='text'
                className={
                  formik.errors.email ? styles.errorInput : styles.input
                }
              />
              {formik.errors.email && (
                <p className={styles.errorText}>{formik.errors.email}</p>
              )}
            </div>
          </div>
        )}
        {medal === 'Win' && (
          <div className={styles.btnBox}>
            <button className={styles.btnWhite} onClick={back}>
              Got it!
            </button>
            <button
              type='submit'
              className={styles.btnBlue}
              onClick={formik.handleSubmit}
            >
              Send!
            </button>
          </div>
        )}
        {medal === 'Looser' && (
          <div className={styles.btnBox}>
            <button className={styles.btnLooser} onClick={back}>
              Got it!
            </button>
          </div>
        )}
      </div>
    </ModalMain>
  )
}

export default CustomAlert

import React from 'react'
import styles from './styles.module.scss'
import { ItemBooleanOne } from '..'

const TestBooleanOneAnswer = ({
  title,
  question,
  answers,
  number,
  tests,
  setTests,
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.box__numberContainer}>
        <p className={styles.box__number}>{number}</p>
      </div>
      <p className={styles.box__title}>{title}</p>
      <p className={styles.box__question}>{question}</p>
      <hr className={styles.box__hr} />
      {answers.map((value) => (
        <ItemBooleanOne
          key={answers.indexOf(value)}
          title={answers.indexOf(value) + 1}
          value={value}
          number={number}
          setTests={setTests}
          tests={tests}
        />
      ))}
    </div>
  )
}

export default TestBooleanOneAnswer

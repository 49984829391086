import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { fetchRequest } from '../../utils/fetchRequest'
import { setToken } from '../../helpers/tokenLS.js'
// validations
import { loginSchema } from 'validations'
// assets
import {
  BigSpiral,
  MiddleSpiral,
  SmallSpiral,
  Logo,
  Phone,
  PasswordIcon,
  SpiralPhoneRight,
  SpiralPhoneLeft,
} from 'assets'
import styles from './styles.module.scss'

const LogIn = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    initialValues: {
      phone: '',
      password: '',
    },

    onSubmit: async (values) => {
      const unmask = formik.values.phone.replace(/[^\d]/g, '')
      const sendData = {
        phone: `+${unmask}`,
        password: formik.values.password,
        deviceId: 'dsa',
      }
      console.log('sendData', sendData)
      const response = await fetchRequest(
        'api/auth/loginEducation',
        'POST',
        sendData
      )

      if (response.status === 200) {
        navigate('/Tests')
        setToken(response.appToken)
      } else {
        setErrorMessage('incorrect login or password')
      }
    },
  })

  return (
    <div>
      <div className={styles.logIn}>
        <div className={styles.logIn__inner}>
          <div className={styles.logIn__logo}>
            <Logo />
          </div>

          <div className={styles.logIn__titleContainer}>
            <p className={styles.logIn__title}>Sign Into</p>
            <p className={styles.logIn__title}>Your Account</p>
          </div>

          <div className={styles.logIn__inputBox}>
            <div className={styles.logIn__phoneIcon}>
              <Phone />
            </div>
            <div className={styles.logIn__inputContainer}>
              <p className={styles.logIn__p}>Phone number</p>
              {/* <input
                label='phone'
                placeholder='Phone'
                id='phone'
                name='phone'
                type='phone'
                className={
                  formik.errors.phone
                    ? styles.logIn__errorInput
                    : styles.logIn__input
                }
                onChange={(value) => {
                  formik.handleChange('phone')(value)
                  delete formik.errors.phone
                  setErrorMessage('')
                }}
                value={formik.values.phone}
              /> */}
              <InputMask
                mask='+1 (999) 999 9999'
                maskPlaceholder={null}
                label='phone'
                placeholder='Phone'
                id='phone'
                name='phone'
                type='phone'
                className={
                  formik.errors.phone
                    ? styles.logIn__errorInput
                    : styles.logIn__input
                }
                onChange={(value) => {
                  formik.handleChange('phone')(value)
                  delete formik.errors.phone
                  setErrorMessage('')
                }}
                value={formik.values.phone}
                alwaysShowMask={false}
                maskChar=' '
              />
              {formik.errors.phone && (
                <p className={styles.logIn__errorText}>{formik.errors.phone}</p>
              )}
            </div>
          </div>

          <div className={styles.logIn__inputBox}>
            <div className={styles.logIn__phoneIcon}>
              <PasswordIcon />
            </div>
            <div className={styles.logIn__inputContainer}>
              <p className={styles.logIn__p}>Password</p>
              <input
                onChange={(value) => {
                  formik.handleChange('password')(value)
                  delete formik.errors.password
                  setErrorMessage('')
                }}
                value={formik.values.password}
                error={formik.errors.password}
                label='password'
                placeholder='Password'
                id='password'
                name='password'
                type='password'
                className={
                  formik.errors.password
                    ? styles.logIn__errorInput
                    : styles.logIn__input
                }
              />
              {formik.errors.password && (
                <p className={styles.logIn__errorText}>
                  {formik.errors.password}
                </p>
              )}
            </div>
          </div>

          <div className={styles.logIn__btnContainer}>
            {errorMessage && (
              <p className={styles.logIn__errorMessage}>{errorMessage}</p>
            )}
            <button
              type='submit'
              className={styles.logIn__btn}
              onClick={formik.handleSubmit}
            >
              Sing in
            </button>
          </div>
        </div>

        <div className={styles.logIn__bigSpiral}>
          <BigSpiral />
        </div>

        <div className={styles.logIn__middleSpiral}>
          <MiddleSpiral />
        </div>

        <div className={styles.logIn__smallSpiral}>
          <SmallSpiral />
        </div>
      </div>

      <div className={styles.signPhone}>
        <div className={styles.signPhone__rightSpiral}>
          <SpiralPhoneRight />
        </div>

        <div className={styles.signPhone__leftSpiral}>
          <SpiralPhoneLeft />
        </div>

        <div className={styles.signPhone__logo}>
          <Logo />
        </div>

        <div className={styles.signPhone__mainContainer}>
          <div className={styles.logIn__titleContainer}>
            <p className={styles.signPhone__title}>Sign Into</p>
            <p className={styles.signPhone__title}>Your Account</p>
          </div>

          <div className={styles.logIn__inputBox}>
            <div className={styles.logIn__phoneIcon}>
              <Phone />
            </div>
            <div className={styles.logIn__inputContainer}>
              <p className={styles.logIn__p}>Phone number</p>
              <InputMask
                mask='+1 (999) 999 9999'
                maskPlaceholder={null}
                label='phone'
                placeholder='Phone'
                id='phone'
                name='phone'
                type='phone'
                className={
                  formik.errors.phone
                    ? styles.logIn__errorInput
                    : styles.logIn__input
                }
                onChange={(value) => {
                  formik.handleChange('phone')(value)
                  delete formik.errors.phone
                  setErrorMessage('')
                }}
                value={formik.values.phone}
                alwaysShowMask={false}
                maskChar=' '
              />
              {formik.errors.phone && (
                <p className={styles.logIn__errorText}>{formik.errors.phone}</p>
              )}
            </div>
          </div>

          <div className={styles.logIn__inputBox}>
            <div className={styles.logIn__phoneIcon}>
              <PasswordIcon />
            </div>
            <div className={styles.logIn__inputContainer}>
              <p className={styles.logIn__p}>Password</p>
              <input
                onChange={(value) => {
                  formik.handleChange('password')(value)
                  delete formik.errors.password
                  setErrorMessage('')
                }}
                value={formik.values.password}
                error={formik.errors.password}
                label='password'
                placeholder='Password'
                id='password'
                name='password'
                type='password'
                className={
                  formik.errors.password
                    ? styles.logIn__errorInput
                    : styles.logIn__input
                }
              />
              {formik.errors.password && (
                <p className={styles.logIn__errorText}>
                  {formik.errors.password}
                </p>
              )}
            </div>
          </div>

          <div className={styles.signPhone__btnContainer}>
            {errorMessage && (
              <p className={styles.logIn__errorMessage}>{errorMessage}</p>
            )}
            <button
              type='submit'
              className={styles.signPhone__btn}
              onClick={formik.handleSubmit}
            >
              Sing in
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogIn

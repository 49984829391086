export const questions = [
  {
    number: 1,
    title: 'Сhoose one of the correct answers:',
    question:
      'What are the benefits of becoming an ITHOS certified instructor?',
    answers: [
      'Helps to limit liability & take advantage of liability insurance',
      'Supports a National Certification system',
      'Helps establish schools',
      'Offers information to everyone concerning safety, training & locations to participate',
      'A and B',
      'A,B,C and D ',
    ],
    numberKey: true,
  },
  {
    number: 2,
    title: 'Сhoose one of the correct answers:',
    question:
      'What are the responsibilities of being an ITHOS certified instructor?',
    answers: [
      'Safety',
      'Communication',
      'Organization',
      'Liability coverage',
      'Patience',
      'All of the above',
    ],
  },
  {
    number: 3,
    title: 'Сhoose one of the correct answers:',
    question: 'What is the easiest way to identify the wind window?',
    answers: [
      'Use a forecasting application',
      'Listen to another rider',
      'Turn your back to the wind with your arms stretched to either side',
      'Observe other riders',
    ],
  },
  {
    number: 4,
    title: 'Сhoose one of the correct answers:',
    question: 'What indicators can you use to evaluate wind conditions?',
    answers: [
      'Flags',
      'Observe other riders',
      'Forecasting applications',
      'All of the above',
    ],
  },
  {
    number: 5,
    title: 'Сhoose one of the correct answers:',
    question: 'What is a wind shadow?',
    answers: [
      'A drop in the wind created by an object upwind of you',
      'A downgrade in the wind',
      'Temporary drop in the wind',
      'Cloud effects on the wind',
    ],
  },
  {
    number: 6,
    title: 'Сhoose one of the correct answers:',
    question:
      'What is the safest way to perform a person recovery on the water?',
    answers: [
      'Dispatch a vessel',
      'Have the person hang onto you',
      'Body drag with the person',
      'Ask another rider for assistance',
    ],
  },
  {
    number: 7,
    title: 'Сhoose one of the correct answers:',
    question:
      'What is the quickest and preferred way to perform a board recovery for another rider?',
    answers: [
      'Grab the board end/strap and ride with the board trailing in your lead hand',
      'Go back to shore, and call a vessel',
    ],
  },
  {
    number: 8,
    title: 'Сhoose one of the correct answers:',
    question: 'Which of these items are required for your student’s lesson?',
    answers: ['Harness and leash', 'Helmet', 'Bar', 'Kite', 'All of the above'],
  },
  {
    number: 9,
    title: 'Сhoose one of the correct answers:',
    question: 'How often should you quiz your student?',
    answers: ['Once weekly', 'After/during each lesson'],
  },
  {
    number: 10,
    title: 'Сhoose one of the correct answers:',
    question:
      'Which is the first primary safety action your student should demonstrate when they lose control of the kite?',
    answers: ['Pull the chicken loop safety', 'Release the bar'],
  },
  {
    number: 11,
    title: 'Сhoose one of the correct answers:',
    question:
      'If your student is being pulled by a depowered kite, floating on the water which safety action should they use?',
    answers: ['Pull the chicken loop safety', 'Eject the leash safety'],
  },
  {
    number: 12,
    title: 'Сhoose one of the correct answers:',
    question:
      'If your student is being pulled by a depowered kite, after engaging the second primary safety action and is in fear of their life, which of the following should they do?',
    answers: ['Eject the leash safety', 'Attempt to pull the kite toward them'],
  },
]

export const questionsMany13 = [
  {
    number: 13,
    title: 'Сhoose one of the correct answers:',
    question:
      'Please list the proper order for recovering your kite on the water.',
    answers: [
      'Leash into the kite',
      'Pull the chicken loop safety',
      'Pull on the line until you reach the kite',
      'All of the above',
    ],
  },
]

export const questionsOne14_16 = [
  {
    number: 14,
    title: 'Сhoose one of the correct answers:',
    question: 'What is the purpose of the inflatable leading edge of the kite?',
    answers: [
      'To easily re-launch the kite',
      'Act as a flotation device',
      'To keep the kite flying steadily',
      'All of the above',
    ],
  },
  {
    number: 15,
    title: 'Сhoose one of the correct answers:',
    question: 'The center bridle lines correspond to?',
    answers: ['Power', 'Direction'],
  },
  {
    number: 16,
    title: 'Сhoose one of the correct answers:',
    question: 'The exterior lines correspond to?',
    answers: ['Power', 'Direction'],
  },
]

export const questionsMany17 = [
  {
    number: 17,
    title: 'Сhoose one of the correct answers:',
    question:
      'Which are the 5 things you must pay attention to on the water for your student?',
    answers: [
      'Weather',
      'Obstructions',
      'Riders',
      'The tide',
      'Vessels',
      'Other people on shore',
    ],
  },
]

export const questionsOne18 = [
  {
    number: 18,
    title: 'Сhoose one of the correct answers:',
    question:
      'What is the proper method of teaching a student new information or skills?',
    answers: [
      'Explain, demonstrate, quiz',
      'Demonstrate, quiz',
      'Observe other riders, quiz',
    ],
  },
]

export const questionsMany19 = [
  {
    number: 19,
    title: 'Сhoose one of the correct answers:',
    question:
      'Please place in order the proper process to perform a self rescue.',
    answers: [
      'While sitting on the kite, use one corner to sail to shore',
      'Unleash from the bar and leash into the kite directly',
      'Pull the chicken loop safety, and use the one line to bring the kite to you',
      'Sit on the kite and wrap up the lines',
    ],
  },
]

export const questionsBooleanMany = [
  {
    number: 20,
    title: 'Сhoose one of the correct answers:',
    question:
      'The following are all requirements for your student to be an independent rider. True or False.',
    answers: [
      'Launch and land the kite',
      'Perform water start',
      'Ride upwind and downwind easily',
      'Transition to both sides',
    ],
  },
]

export const questionsBoolean21_23 = [
  {
    number: 21,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: [
      'Incoming (landing) riders have the right of way over outgoing (launching) students.',
    ],
  },
  {
    number: 22,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: ['Riders on the lip/top of a wave have the right of way.'],
  },
  {
    number: 23,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: [
      'Students should purchase their gear before they are independent.',
    ],
  },
]

export const questionsOne24 = [
  {
    number: 24,
    title: 'Сhoose one of the correct answers:',
    question:
      'Which of these items and conditions should an instructor utilize to best accommodate students who specifically cannot swim?',
    answers: [
      'Impact vest, life jacket, helmet, low tide',
      'Life jacket, low tide',
      'Life jacket',
    ],
  },
]

export const questionsBoolean25 = [
  {
    number: 25,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: ['Students should learn to always launch from shore.'],
  },
]

export const questionsOne26 = [
  {
    number: 26,
    title: 'Сhoose one of the correct answers:',
    question:
      'Which is the safest method for new riders to correct inverted lines?',
    answers: [
      'Eject the kite',
      'Juggle the bar',
      'Walk back to shore and re-connect the lines',
      'Re-connect the lines on the water',
    ],
  },
]

export const questionsBoolean27_28 = [
  {
    number: 27,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: [
      'Students should be able to ride upwind and downwind before practicing transitions.',
    ],
  },
  {
    number: 28,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: [
      'Students should be able to transition comfortably before learning basic jumps.',
    ],
  },
]

export const questionsOne29 = [
  {
    number: 29,
    title: 'Сhoose one of the correct answers:',
    question:
      'What are the three primary safety procedures when riding and then panicking?',
    answers: [
      'Eject the kite, walk back to shore',
      'Release the control bar, eject the chicken loop safety, eject the leash safety',
      'Eject the chicken loop safety, get to the instructor, Hang onto your instructors harness',
    ],
  },
]

export const questionsBoolean30 = [
  {
    number: 30,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: [
      'Diving the kite aggressively and redirecting upwards is called a “power stroke.”',
    ],
  },
]

export const questionsOne31 = [
  {
    number: 31,
    title: 'Сhoose one of the correct answers:',
    question: 'The main virtue(s) an instructor must have is?',
    answers: [
      'Patience',
      'Extensive kiting tricks',
      'Good communication',
      'Patience, and good communication',
    ],
  },
]

export const questionsBoolean32 = [
  {
    number: 32,
    title: 'Сhoose one of the correct answers:',
    question: 'True or False.',
    answers: ['An instructor is responsible for their student’s safety.'],
  },
]

import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { ItemOneAnswer } from '..'

const TestWithOneAnswer = ({
  title,
  question,
  answers,
  number,
  numberKey,
  tests,
  setTests,
}) => {
  const [activeValue, setActiveValue] = useState(null)

  const handleKey = (key) => {
    if (key === 1) {
      return 'A'
    } else if (key === 2) {
      return 'B'
    } else if (key === 3) {
      return 'C'
    } else if (key === 4) {
      return 'D'
    } else if (key === 5) {
      return 'E'
    } else if (key === 6) {
      return 'F'
    } else {
      return 'G'
    }
  }

  return (
    <div className={styles.box}>
      <div className={styles.box__numberContainer}>
        <p className={styles.box__number}>{number}</p>
      </div>
      <p className={styles.box__title}>{title}</p>
      <p className={styles.box__question}>{question}</p>
      <hr className={styles.box__hr} />
      {answers.map((value) => (
        <ItemOneAnswer
          key={answers.indexOf(value)}
          title={
            !numberKey
              ? handleKey(answers.indexOf(value) + 1)
              : answers.indexOf(value) + 1
          }
          value={value}
          setActiveValue={setActiveValue}
          activeValue={activeValue}
          tests={tests}
          setTests={setTests}
          number={number}
        />
      ))}
    </div>
  )
}

export default TestWithOneAnswer

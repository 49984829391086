import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'

const ItemBooleanOne = ({ title, value, setTests, tests, number }) => {
  const [radio, setRadio] = useState([])

  useEffect(() => {
    if (setTests) {
      const findIndex = tests.findIndex((item) => item.question === number)
      if (findIndex === -1) {
        setTests((prev) => [
          ...prev,
          { answer: [`${radio}`], question: number },
        ])
      } else {
        setTests(tests.filter((item) => item.question !== number))
        setTests((prev) => [
          ...prev,
          { answer: [`${radio}`], question: number },
        ])
      }
    }
  }, [radio])

  const handleDataTest = () => {
    const findIndex = tests.findIndex((item) => item.question === number)
    if (findIndex === -1) {
      setTests((prev) => [...prev, { answer: [`${radio}`], question: number }])
    } else {
      setTests(tests.filter((item) => item.question !== number))
      setTests((prev) => [...prev, { answer: [`${radio}`], question: number }])
    }
  }

  return (
    <div className={styles.answer}>
      <div className={styles.answer__top}>
        <p className={styles.answer__value}>{value}</p>
      </div>
      <div className={styles.answer__radioBox}>
        <input
          value='True'
          type='radio'
          checked={radio === 'True'}
          onChange={(e) => setRadio(e.target.value)}
          onClick={handleDataTest}
        />
        <label className={styles.answer__label} htmlFor='true'>
          True
        </label>
        <input
          value='False'
          type='radio'
          checked={radio === 'False'}
          onChange={(e) => setRadio(e.target.value)}
          onClick={handleDataTest}
        />
        <label className={styles.answer__label} htmlFor='false'>
          False
        </label>
      </div>
    </div>
  )
}

export default ItemBooleanOne

import React from 'react'
import styles from './styles.module.scss'

const ItemOneAnswer = ({
  title,
  value,
  setActiveValue,
  activeValue,
  tests,
  setTests,
  number,
}) => {
  const handleDataTest = () => {
    setActiveValue(title)
    const findIndex = tests.findIndex((item) => item.question === number)
    if (findIndex === -1) {
      setTests((prev) => [...prev, { answer: [`${title}`], question: number }])
    } else {
      setTests(tests.filter((item) => item.question !== number))
      setTests((prev) => [...prev, { answer: [`${title}`], question: number }])
    }
  }

  return (
    <div className={styles.answer} onClick={handleDataTest}>
      <p
        className={
          activeValue === title
            ? styles.answer__activeTitle
            : styles.answer__title
        }
      >
        {title}
      </p>
      <p
        className={
          activeValue === title
            ? styles.answer__activeValue
            : styles.answer__value
        }
      >
        {value}
      </p>
    </div>
  )
}

export default ItemOneAnswer

import React from 'react'
import styles from './styles.module.scss'
import { ItemBooleanMany } from '..'

const TestWithBooleanMany = ({
  title,
  question,
  answers,
  number,
  setTests,
  tests,
  value,
  onChange,
}) => {
  const handleChangeItem = (idx, val) => {
    const newValue = [...value]
    newValue[idx] = val
    onChange(newValue, number)
  }

  return (
    <div className={styles.box}>
      <div className={styles.box__numberContainer}>
        <p className={styles.box__number}>{number}</p>
      </div>
      <p className={styles.box__title}>{title}</p>
      <p className={styles.box__question}>{question}</p>
      <hr className={styles.box__hr} />
      {answers.map((v, index) => (
        <ItemBooleanMany
          key={answers.indexOf(v)}
          title={answers.indexOf(v) + 1}
          itemValue={v}
          value={value[index]}
          setTests={setTests}
          tests={tests}
          number={number}
          onChange={(val) => handleChangeItem(index, val)}
        />
      ))}
    </div>
  )
}

export default TestWithBooleanMany

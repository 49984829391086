import LogIn from './pages/LogIn'
import Tests from './pages/Tests'
import { Routes, Route } from 'react-router-dom'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<LogIn />} />
      <Route path='/tests' element={<Tests />} />
    </Routes>
  )
}

export default App

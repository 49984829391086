import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { ItemManyAnswer } from '..'

const TestWithManyAnswer = ({
  title,
  question,
  answers,
  number,
  setTests,
  tests,
}) => {
  const [arrayValue, setArrayValue] = useState([])

  const handleKey = (key) => {
    if (key === 1) {
      return 'A'
    } else if (key === 2) {
      return 'B'
    } else if (key === 3) {
      return 'C'
    } else if (key === 4) {
      return 'D'
    } else if (key === 5) {
      return 'E'
    } else if (key === 6) {
      return 'F'
    } else {
      return 'G'
    }
  }

  useEffect(() => {
    if (setTests) {
      const findIndex = tests.findIndex((item) => item.question === number)
      if (findIndex === -1) {
        setTests((prev) => [...prev, { answer: arrayValue, question: number }])
      } else {
        setTests(tests.filter((item) => item.question !== number))
        setTests((prev) => [...prev, { answer: arrayValue, question: number }])
      }
    }
  }, [arrayValue.length])

  const handleDataTest = (val) => {
    const findIndexValue = arrayValue.findIndex((item) => item === val)
    if (findIndexValue === -1) {
      setArrayValue((prev) => [...prev, val])
    } else {
      setArrayValue(arrayValue.filter((item) => item !== val))
    }
  }

  return (
    <div className={styles.box}>
      <div className={styles.box__numberContainer}>
        <p className={styles.box__number}>{number}</p>
      </div>
      <p className={styles.box__title}>{title}</p>
      <p className={styles.box__question}>{question}</p>
      <hr className={styles.box__hr} />
      {answers.map((value) => (
        <ItemManyAnswer
          key={answers.indexOf(value)}
          title={handleKey(answers.indexOf(value) + 1)}
          value={value}
          number={number}
          handleDataTest={handleDataTest}
        />
      ))}
      <p className={styles.box__answers}>{arrayValue.join(', ')}</p>
    </div>
  )
}

export default TestWithManyAnswer

import React, { useState, useEffect } from 'react'
// components
import {
  TestWithOneAnswer,
  TestWithManyAnswer,
  TestWithBooleanMany,
  TestBooleanOneAnswer,
  CustomAlert,
} from '../../components'
// consts
import {
  questions,
  questionsMany13,
  questionsOne14_16,
  questionsMany17,
  questionsOne18,
  questionsMany19,
  questionsBooleanMany,
  questionsBoolean21_23,
  questionsOne24,
  questionsBoolean25,
  questionsOne26,
  questionsBoolean27_28,
  questionsOne29,
  questionsBoolean30,
  questionsOne31,
  questionsBoolean32,
} from '../../consts/questions'
// assets
import { Laptop, Logo } from 'assets'
import { fetchRequest } from '../../utils/fetchRequest'
import { useToggleModal } from 'hooks'
import moment from 'moment'
import styles from './styles.module.scss'

const Tests = () => {
  const [tests, setTests] = useState([
    {
      answer: [null, null, null, null],
      question: 20,
    },
  ])
  const customAlert = useToggleModal()
  const [errorMessage, setErrorMessage] = useState('')
  const [medal, setMedal] = useState('')
  const [mark, setMark] = useState(null)
  const [user, setUser] = useState([])

  const manyRadioQuestions = tests.find((v) => v.question === 20)

  const handleChange = (value, num) => {
    const index = tests.findIndex((i) => i.question === num)
    const newTests = [...tests]
    newTests[index] = { answer: value, question: num }
    setTests(newTests)
  }

  const fetchGetUser = async () => {
    const response = await fetchRequest('api/user/get-own-profile', 'GET')
    if (response.status === 200) {
      setUser(response)
    }
  }

  useEffect(() => {
    fetchGetUser()
  }, [])

  console.log('user', user?.profile)

  const fetchCheckExam = async () => {
    const answers = {
      answers: tests,
    }
    console.log('answers', answers)
    const response = await fetchRequest('api/exam/check', 'POST', answers)
    if (response.status === 200) {
      if (response.mark >= 25) {
        setMark(response.mark)
        setMedal('Win')
        customAlert.showModal()
      } else {
        setMark(response.mark)
        setMedal('Looser')
        customAlert.showModal()
      }
    } else if (response.status === 403) {
      setErrorMessage(response.message)
    } else {
      setErrorMessage('Not all fields are selected!')
    }
  }
  return (
    <>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h1>
              Wingtips: Ithos <br /> Certification
            </h1>
            <p>
              Wingtips, an app that builds a community of people with things
              that can be used and reused to their best and fullest potential.
              Providing access by eliminating excess.
              <br />
              <br />
              You can be part of a community that utilizes everyone’s things in
              a full circle of giving and receiving. Feel like sharing all your
              neat things that you don’t use often enough...or at all?
              BorrowingOwl allows users to share things they already own to
              interested people in their community. Do you have a guitar that is
              sitting in your room collecting dust? You can let a nearby
              neighbor borrow that guitar, allow them an opportunity to learn a
              new skill, free up some space, AND get it back when you need it.
            </p>
          </div>
          <div className={styles.laptop}>
            <Laptop />
          </div>
        </div>
        <div className={styles.main}>
          <h1 className={styles.main__title}>Ithos Certification</h1>
          <hr className={styles.main__hr} />
          <p className={styles.main__date}>
            Date of creation: {moment(new Date()).format('DD.MM.YYYY')}
          </p>
          <hr className={styles.main__hr} />

          <div className={styles.main__levelContainer}>
            <div className={styles.main__levelSelect}>
              <p>Level 1</p>
              {/* <ArrowDown /> */}
            </div>
            <div className={styles.main__name}>
              <p>{user?.profile?.login}</p>
            </div>
          </div>
          {questions.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsMany13.map((item) => (
            <TestWithManyAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne14_16.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsMany17.map((item) => (
            <TestWithManyAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne18.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsMany19.map((item) => (
            <TestWithManyAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsBooleanMany.map((item) => (
            <TestWithBooleanMany
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              questionAdd={item.questionAdd}
              setTests={setTests}
              tests={tests}
              value={manyRadioQuestions.answer}
              onChange={handleChange}
            />
          ))}
          {questionsBoolean21_23.map((item) => (
            <TestBooleanOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne24.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsBoolean25.map((item) => (
            <TestBooleanOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne26.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsBoolean27_28.map((item) => (
            <TestBooleanOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne29.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsBoolean30.map((item) => (
            <TestBooleanOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          {questionsOne31.map((item) => (
            <TestWithOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
              numberKey={item.numberKey}
            />
          ))}
          {questionsBoolean32.map((item) => (
            <TestBooleanOneAnswer
              number={item.number}
              title={item.title}
              question={item.question}
              answers={item.answers}
              setTests={setTests}
              tests={tests}
            />
          ))}
          <div className={styles.main__btnContainer}>
            <p className={styles.main__errorMessage}>{errorMessage}</p>
            <button className={styles.main__btn} onClick={fetchCheckExam}>
              Show test results
            </button>
          </div>
        </div>
        <div className={styles.footer}>
          <Logo />
        </div>
      </div>
      {customAlert.isShow && (
        <CustomAlert
          showModal={customAlert.showModal}
          closeModal={customAlert.closeModal}
          isOpen={customAlert.isShow}
          title={`dsadasd`}
          text={`Are you sure you want to ban instructor?`}
          titleBtn={`Ban`}
          medal={medal}
          mark={mark}
          // onClick={instructorBanToggle}
        />
      )}
    </>
  )
}

export default Tests

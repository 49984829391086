import React, { useState } from 'react'
import styles from './styles.module.scss'

const ItemManyAnswer = ({ title, value, number, handleDataTest }) => {
  const [activeValue, setActiveValue] = useState(false)

  const severalFunc = () => {
    setActiveValue((prev) => !prev)
    handleDataTest(title)
  }

  return (
    <div className={styles.answer} onClick={severalFunc}>
      <p
        className={
          activeValue ? styles.answer__activeTitle : styles.answer__title
        }
      >
        {title}
      </p>
      <p
        className={
          activeValue ? styles.answer__activeValue : styles.answer__value
        }
      >
        {value}
      </p>
    </div>
  )
}

export default ItemManyAnswer

import { getToken } from '../helpers/tokenLS'

export const fetchRequest = async (url, method, body, Token) => {
  let token = Token || (await getToken())

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  console.log('token', token)

  let requestParams = {
    method: method.toUpperCase(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  if (body) {
    requestParams.body = JSON.stringify(body)
  }

  try {
    if (token) headers.Authorization = `Bearer ${token}`

    // const response = await fetch(`https://ithostech.com/${url}`)
    const response = await fetch(`https://ithostech.com/${url}`, requestParams)

    if (response.status === 200 || 201) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 400 || 401) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 403) {
      const data = false
      return data
    } else if (response.status === 500) {
      alert('Sorry the server is not available right now please try later')
    }
  } catch (e) {
    console.log(e)
  }
}
